<script lang="ts" setup>
import { useStorage } from '@vueuse/core'

const props = withDefaults(defineProps<Options>(), { contact: true, containerClass: 'max-w-[1200px]' })
const auth = useAuth()
const { project } = storeToRefs(auth)
const toolsAndServicesTab = useStorage<number>('forgd:nav:tools-services-tab', project.value?.tokenListed ? 1 : 0)

interface Options {
  title?: string
  icon?: string
  cta?: string
  ctaBtnText?: string
  contact?: boolean
  containerClass?: false | string
}

const options = computed<Options>(() => {
  return { ...props, ...useRoute().meta }
})

const navSubItems = [
  ...preTgeNav.map(item => ({ ...item, preTge: true })),
  ...listedTokenNav.map(item => ({ ...item, preTge: false })),
]
  .flatMap(item => (item.children || []).map(child => ({ icon: item.icon, preTge: item.preTge, ...child })))
  .filter(item => item.to) as { to: string, label: string, icon?: string, preTge: boolean }[]

const tools = navSubItems
  .filter(item => item.to.startsWith('/') && !item.to.startsWith('/partner')) // relative links only, non-partner
  // we need to do a unique by for the .to as we'll have duplicates
  .filter((item, index, self) => self.findIndex(t => t.to === item.to) === index)

const _workbooks = navSubItems
  .filter(item => item.to.startsWith('https://docs.google.com'))
  .filter((item, index, self) => self.findIndex(t => t.to === item.to) === index)
const workbooks = _workbooks
  .map((item) => {
    item.icon = 'i-heroicons-document-chart-bar'
    const hasDuplicateLabel = _workbooks.filter(t => t.label === item.label).length > 1
    if (hasDuplicateLabel) {
      return ({ ...item, label: (item.preTge ? 'Pre-TGE: ' : 'Post-TGE: ') + item.label })
    }
    return item
  })

const thirdParty = navSubItems
  .filter(item => item.to.startsWith('/') && item.to.startsWith('/partner'))
  .filter((item, index, self) => self.findIndex(t => t.label === item.label) === index)

const groups = [
  {
    key: 'tools',
    label: 'In-House Tools',
    commands: tools,
  },
  {
    key: 'workbooks',
    label: 'Forgd Workbooks',
    commands: workbooks,
  },
  {
    key: 'third-party',
    label: '3rd Party Tools',
    commands: thirdParty,
  },
]

const router = useRouter()
const runtimeConfig = useRuntimeConfig()

const teammateAccessWall = useTeammateAccessWall()

const shouldDisplayContactUsBannerInFooter = computed(() => {
  const availableRoutes = [
    'playbook',
    'playbook-basic',
    'token-settings',
    'token-designer',
    'active-dashboard',
    'market-maker-monitoring-start',
    'market-maker-monitoring-dashboard',
  ]
  return availableRoutes.includes(router.currentRoute.value.name)
})
</script>

<template>
  <div>
    <NuxtLayout name="core-dashboard" v-bind="props">
      <template v-if="$slots.sidebar" #sidebar>
        <slot name="sidebar" />
      </template>
      <template #header>
        <slot name="header" />
      </template>
      <template #footer>
        <slot name="footer" />
        <div v-if="shouldDisplayContactUsBannerInFooter && options.contact" class="mx-auto border-t border-forgd-gray-300" :class="containerClass ? [containerClass, 'p-5'] : []">
          <!-- Footer call to action, something generic -->
          <AppContact class="!mt-0">
            <template #default>
              {{ options.cta || 'Contact us for a 1:1 consultation regarding all things related to your project.' }}
            </template>
            <template #cta>
              <UButton
                size="xl"
                color="black"
                class="px-12 py-3"
                :to="runtimeConfig.public.consultationUrl"
                target="_blank"
              >
                {{ options.ctaBtnText || 'Schedule a 1:1 Consultation' }}
              </UButton>
            </template>
          </AppContact>
        </div>
      </template>
      <template #slideover="{ modelValue, navTitle: NavTitle, navList: NavList }">
        <nav v-if="modelValue === 'forgd-academy'" aria-label="Forgd Academy Navigation">
          <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
            <div class="text-sm font-semibold text-forgd-primary-900">
              <div>Forgd Academy</div>
            </div>
          </div>
          <div v-for="(item, key) in forgdAcademyNav" :key="key" class="p-4">
            <component :is="NavTitle" :label="item.label" :to="item.to" :icon="item.icon" />
            <component :is="NavList" :items="item.children" />
          </div>
        </nav>
        <div v-else-if="modelValue === 'tools-and-services'">
          <div class="bg-white sticky top-0 p-4 border-b border-b-neutral-600" :style="{ zIndex: 2 }">
            <div class="text-sm mb-2 font-semibold text-forgd-primary-900">
              <div>Tools & Services</div>
            </div>
            <UiSwitch
              v-model="toolsAndServicesTab"
              :options="{
                'Pre-TGE Token': 0,
                'Listed Token': 1,
              }"
              class="w-full"
            />
          </div>
          <div class="p-4">
            <nav v-if="toolsAndServicesTab === 0" aria-label="Pre TGE Token Navigation" class="space-y-4">
              <div v-for="(item, key) in preTgeNav" :key="key">
                <component :is="NavTitle" :label="item.label" :to="item.to" :icon="item.icon" />
                <component :is="NavList" :items="item.children" />
              </div>
            </nav>
            <nav v-else-if="toolsAndServicesTab === 1" aria-label="Listed Token Navigation" class="space-y-4">
              <div v-for="(item, key) in listedTokenNav" :key="key">
                <component :is="NavTitle" :label="item.label" :to="item.to" :icon="item.icon" />
                <component :is="NavList" :items="item.children" />
              </div>
            </nav>
          </div>
        </div>
      </template>
      <slot />
    </NuxtLayout>
    <SwitchAccountProfileModal />
    <DashboardTeammateAccessWall
      :show="teammateAccessWall.isVisible"
      :project="project"
      @close="teammateAccessWall.hide()"
    />
    <ClientOnly>
      <LazyCoreSearchModal :groups="groups" />
    </ClientOnly>
  </div>
</template>
