<script setup lang="ts">
import { useSwitchAccountProfileStore } from '../composables/useSwitchAccountProfileStore'

const authStore = useAuth()
const switchAccountProfileStore = useSwitchAccountProfileStore()
const { isModalOpen, state, mode } = storeToRefs(switchAccountProfileStore)
const { closeModal } = switchAccountProfileStore
const { project } = storeToRefs(authStore)

async function handleConfirm() {
  try {
    state.value = 'processing'
    const result = await useQuery('/projects/{projectId}/promote-research', {
      method: 'patch',
      params: {
        path: {
          projectId: project.value!.id,
        },
      },
      options: {
        toast: true,
      },
    })
    if (result.error.value) {
      throw result.error.value
    }

    state.value = 'success'
    authStore.refresh({ from: 'switch-account-profile-modal' })
  }
  catch {
    state.value = 'prompt'
  }
}

async function handleEditTokenDetails() {
  navigateTo('/token-settings')
  closeModal()
}
</script>

<template>
  <UiModal :is-open="isModalOpen" @close="closeModal">
    <UiStack v-if="state === 'prompt' && mode === 'confirm'" class="h-[250px]">
      <div>
        <UiIcon name="i-heroicons-exclamation-circle" class="w-10 h-10 text-forgd-primary-300" />
        <div class="mb-3 font-semibold text-xl text-primary-900">
          You are about to switch the account profile.
        </div>
        <div class="mb-2 text-gray-600 text-base">
          Don't worry - your progress will be saved, and no work will be lost. <br><br>
          Do you want to continue?
        </div>
      </div>
      <div class="flex items-center w-full gap-3">
        <UiButton variant="outline" class="grow basis-0" @click="closeModal">
          Cancel
        </UiButton>
        <UiButton variant="solid" class="grow basis-0" @click="handleConfirm">
          Continue
        </UiButton>
      </div>
    </UiStack>
    <UiStack v-else-if="state === 'prompt' && mode === 'require'" class="h-[250px]">
      <div>
        <UiIcon name="i-heroicons-exclamation-circle" class="w-10 h-10 text-forgd-primary-300" />
        <div class="mb-3 font-semibold text-xl text-primary-900">
          You are currently working with a researcher profile account.
        </div>
        <div class="mb-2 text-gray-600 text-base">
          To continue with this feature, you'll need to update your account to a "project" profile. Don't worry - your progress will be saved, and no work will be lost.
        </div>
      </div>
      <div class="flex items-center w-full gap-3">
        <UiButton variant="outline" class="grow basis-0" @click="closeModal">
          Cancel
        </UiButton>
        <UiButton variant="solid" class="grow basis-0" @click="handleConfirm">
          Switch Account Profile
        </UiButton>
      </div>
    </UiStack>
    <div v-else-if="state === 'processing'" class="flex flex-col items-center justify-center h-[250px]">
      <UiLottie name="processing" size="sm" />
      <div class="mb-3 font-semibold text-xl text-primary-900">
        We are processing your submission...
      </div>
      <div class="text-gray-600 text-base">
        Do not close this window
      </div>
    </div>
    <UiStack v-else-if="state === 'success'">
      <div>
        <UiIcon name="i-heroicons-check-circle" class="w-10 h-10 text-green-600" />
        <div class="mb-3 font-semibold text-xl text-primary-900">
          Your account has been updated
        </div>
        <div class="mb-2 text-gray-600 text-base">
          Your account is now set up with full access to all Forgd tools. We recommend customizing your token details (name, ticker, image, etc.) before proceeding with other tasks.
        </div>
      </div>
      <div class="flex items-center w-full gap-3">
        <UiButton v-if="mode === 'confirm'" variant="outline" class="w-full" @click="closeModal">
          Got it
        </UiButton>
        <template v-if="mode === 'require'">
          <UiButton variant="outline" class="grow basis-0" @click="closeModal">
            I Will Do This Later
          </UiButton>
          <UiButton variant="solid" class="grow basis-0" @click="handleEditTokenDetails">
            Edit Token Details
          </UiButton>
        </template>
      </div>
    </UiStack>
  </UiModal>
</template>
